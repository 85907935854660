/* WebKit browsers (Chrome, Safari, Edge) */
.scrollable-content::-webkit-scrollbar {
  width: 8px; /* Scrollbar thickness */
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: #d3d3d3; /* Light grey color for the scrollbar thumb */
  border-radius: 20px; /* Rounded corners for the thumb */
}

.scrollable-content::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Lighter grey color for the scrollbar track */
  border-radius: 20px; /* Optional: rounded corners for the track */
}

/* Firefox */
.scrollable-content {
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
  scrollbar-color: #d3d3d3 #f1f1f1; /* Light grey thumb and track color for Firefox */
}
.text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.letter {
  animation: fadeinout 1.2s infinite;
}

.letter:nth-child(1) {
  animation-delay: 0s;
}
.letter:nth-child(2) {
  animation-delay: 0.2s;
}
.letter:nth-child(3) {
  animation-delay: 0.4s;
}
.letter:nth-child(4) {
  animation-delay: 0.6s;
}
.letter:nth-child(5) {
  animation-delay: 0.8s;
}
.letter:nth-child(6) {
  animation-delay: 1s;
}
.letter:nth-child(7) {
  animation-delay: 1.2s;
}
.letter:nth-child(8) {
  animation-delay: 1.4s;
}
.letter:nth-child(9) {
  animation-delay: 1.6s;
}
.letter:nth-child(10) {
  animation-delay: 1.8s;
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}