body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto") url("./fonts/Roboto/Roboto-Regular.ttf")
    format("truetype");
  font-weight: bold;
}
* {
  font-family: "Roboto";
}

.MuiDataGrid-row:nth-child(even) {
  background-color: #f5f4f4 !important;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto") url("./fonts/Roboto/Roboto-Regular.ttf")
    format("truetype");
  font-weight: bold;
}

::-webkit-scrollbar {
  width: 3px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #adadad;
}

/* .empcnic {

    animation-name: example;
    animation-duration: 1s;
  }
  
  @keyframes example {
    from {transform: rotateY(0deg);}
    to {transform: rotateY(180deg);}
  } */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.PhoneInputInput {
  padding: 9.5px;
  border-radius: 5px;
  font-size: 16px;
  color: rgb(48, 48, 48);
  border: 1px solid rgb(187, 187, 187);
}
.MuiDataGrid-columnHeaders {
  background: #53b7c5;
  color: white;
}
/* Default styles for larger screens */
.rbc-calendar {
  height: 100%;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
}

.rbc-toolbar .rbc-btn-group button.rbc-active {
  background-color: #53b7c5;
}

/* Adjustments for small screens */
@media (max-width: 600px) {
  .rbc-calendar {
    font-size: 12px; /* Reduce font size for smaller screens */
  }

  .rbc-header {
    padding: 4px; /* Reduce padding for headers */
  }

  .rbc-month-view,
  .rbc-time-view {
    min-height: 400px; /* Ensure calendar has a minimum height */
  }

  .rbc-day-bg + .rbc-day-bg,
  .rbc-header + .rbc-header {
    border-left-width: 1px; /* Reduce border width */
  }

  .rbc-date-cell {
    padding: 2px; /* Reduce padding for date cells */
  }

  .rbc-event {
    font-size: 10px; /* Reduce event font size */
    padding: 2px; /* Reduce event padding */
  }
  .rbc-toolbar {
    display: flex;
    flex-direction: column;
  }
  .rbc-toolbar .rbc-btn-group {
    margin: 8px;
    font-size: 13px;
  }
}
